import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_NAN_PORT}${process.env.VUE_APP_API_PATH}/nan`;


export default {
    obterTodos(filtro) {
        return axiosJwt.get(`${api}/parceiropf?datacadastrode=${filtro.dataCadastroDe}&datacadastroate=${filtro.dataCadastroAte}&status=${filtro.status}`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/parceiropf/${id}`);
    },

    obterParceiroPfPorCpfSSE(cpf) {
        return axiosJwt.get(`${api}/parceiropf/sse/${cpf}`);
    },

    atualizarParceiroPf(pf) {
        return axiosJwt.post(`${api}/parceiropf/atualizar`, pf);
    },

    reprocessar(id) {
        return axiosJwt.patch(`${api}/parceiropf/${id}/reprocessar`);
    },

};
