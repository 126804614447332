<template>
    <painel titulo="Pessoa Física" icone="pi pi-users">
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-3">
                <label>CPF</label>
                <div class="p-inputgroup">
                    <InputMask type="text" v-model="cpf" mask="999.999.999-99" @input="v$.cpf.$touch()" :unmask="true" />

                    <Button
                        icon="pi pi-search"
                        class="p-button-warning"
                        @click="pesquisarPessoaFisica()"
                        v-if="!desabilitaPesquisarLogin"
                        title="Pesquisar pessoa física"
                    />
                    <Button icon="pi pi-refresh" class="p-button-warning" @click="limparPessoaFisica()" v-if="desabilitaRefresh" title="Limpar pessoa física" />
                </div>
                <small class="p-error" v-if="v$.cpf.$error">O "CPF" é obrigatório</small>
            </div>
            <div class="field col-12 md:col-4">
                <label>Nome</label>
                <InputText type="text" v-model="nome" @input="v$.nome.$touch()" />
                <small class="p-error" v-if="v$.nome.$error">O "Nome" é obrigatório</small>
            </div>
            <div class="field col-12 md:col-3">
                <label>Email</label>
                <InputText type="text" v-model="email" @input="v$.email.$touch()" />
                <small class="p-error" v-if="v$.email.$error">O "Email" é obrigatório</small>
            </div>
            <div class="field col-12 md:col-3">
                <label>Celular</label>
                <InputMask type="text" mask="(99)999999999" :unmask="true" v-model="celular" @input="v$.celular.$touch()" />
                <small class="p-error" v-if="v$.celular.$error">O "Celular" é obrigatório</small>
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" v-if="$route.params.cpf == null" class="p-button-secondary" @click="limparPessoaFisica()" />
        <btn-voltar v-if="$route.params.cpf != null" :route="{ name: 'NAN_Parceiro_Pf_Detalhar', params: { id: $route.params.id } }"></btn-voltar>
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" v-if="possuiPf" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
    <router-view></router-view>
</template>
<script>
import ParceiroServices from './services';
import useVuelidate from '@vuelidate/core';

import { required, email } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {},

    props: {
        parceiro: {
            type: Object,
        },
    },

    data() {
        return {
            desabilitaPesquisarLogin: false,
            desabilitaRefresh: false,
            numeroComunicacaoId: null,
            emailComunicacaoId: null,
            parceiroPfIdNAN: null,
            possuiPf: false,
            celular: null,
            email: null,
            nome: null,
            cpf: null,
        };
    },

    validations() {
        return {
            nome: { required },
            cpf: { required },
            email: { required, email },
            celular: { required },
        };
    },

    methods: {
        limparPessoaFisica() {
            this.desabilitaPesquisarLogin = false;
            this.numeroComunicacaoId = null;
            this.desabilitaRefresh = false;
            this.emailComunicacaoId = null;
            this.nome = null;
            this.possuiPf = false;
            this.parceiropfId = null;
            this.celular = null;
            this.email = null;
            this.cpf = null;
            this.v$.$reset();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja atualizar o Parceiro PF?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$store.dispatch('addRequest');
            let pf = {
                numeroComunicacaoId: this.numeroComunicacaoId,
                emailComunicacaoId: this.emailComunicacaoId,
                nome: this.nome,
                parceiroPfIdNAN: this.parceiroPfIdNAN,
                celular: this.celular,
                email: this.email,
                cgcCpf: this.cpf,
            };
            ParceiroServices.atualizarParceiroPf(pf).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Parceiro',
                        detail: 'Parceiro atualizado com sucesso.',
                        life: 3000,
                    });
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Parceiro',
                        detail: 'Erro ao atualizar Parceiro',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        pesquisarPessoaFisica() {
            this.$store.dispatch('addRequest');
            ParceiroServices.obterParceiroPfPorCpfSSE(this.cpf).then((response) => {
                if (response?.success && response.data != '') {
                    this.preencher(response.data);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Parceiro',
                        detail: 'Parceiro não encontrado',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        preencher(parceiropf) {
            this.numeroComunicacaoId = parceiropf.numeroComunicacaoId;
            this.emailComunicacaoId = parceiropf.emailComunicacaoId;
            this.nome = parceiropf.nome;
            this.parceiroPfIdNAN = parceiropf.parceiroPfIdNAN;
            this.celular = parceiropf.celular;
            this.email = parceiropf.email;
            this.desabilitaPesquisarLogin = true;
            this.desabilitaRefresh = true;
            this.possuiPf = true;
        },
    },
    created() {},

    mounted() {
        if (this.$route.params.cpf != null) {
            this.cpf = this.$route.params.cpf;
            this.pesquisarPessoaFisica();
        }
    },

    computed() {},
};
</script>
